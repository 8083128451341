import { Injectable } from '@angular/core';
import { TrelloService } from './trello.service';

const MY_ICON = './assets/TintaNoPano144px.jpg';
const CONFIG_ICON= './assets/config-icon.jpg';

declare var TrelloPowerUp: any;
declare var Trello: any;

@Injectable({
  providedIn: 'root'
})
export class TrelloBootstrapService {

  private c: number;

  constructor(private trelloService: TrelloService) { }

  setToken(token) {
   
    var t = TrelloPowerUp.iframe({
      appKey: 'e3c024c20ea3aee92301be797aeac388',
      appName: 'Tempo na Lista Tinta no Pano'
    });

    t.storeSecret('token', token);

    setTimeout(() => {
      window.close();
    }, 600);
        
  }

  initialize() {
    console.log('Tempo na Lista - TrelloBootstrapService::initialize()');
    this.c = 0;
    TrelloPowerUp.initialize({
      //'card-back-section': this.getCardBackSection,
      //'card-buttons': this.getCardButtons,
      'board-buttons': this.getBoardButtons,
      //'authorization-status': this.getAuthorizationStatus,
      //'show-authorization': this.showAuthorization,
      //'on-disable': this.disable,
      'list-sorters': this.sorter,
      'list-actions': this.getListActions,
      'card-badges': this.getBadges
    }, {
      'appKey': 'e3c024c20ea3aee92301be797aeac388',
      'appName': 'Tempo na Lista Tinta no Pano'
    });
  }


  private sorter = (t, opts) => {
    return [{
      text: 'Código de Cliente',
      callback: this.trelloService.ordenaCodigo
    }];
  }


  private getBadges = (t, opts) => {
    return t.getRestApi()
            .isAuthorized()
            .then(authorized => { 
              if (!authorized) 
                return; 
              else
                return this.trelloService.getBadgeData(t, opts);
            });    
  }  

  private getBoardButtons = (t, opts) => {
    //console.log('Tempo na Lista - TrelloBootstrapService::getBoardButtons()');
    let a = t.getRestApi();
    return t.getRestApi()
            .isAuthorized()
            .then(authorized => { 
              //console.log(`Tempo na Lista - authorized=${authorized}`);
              if (!authorized) 
                return[{
                  // usually you will provide a callback function to be run on button click
                  // we recommend that you use a popup on click generally
                  icon: CONFIG_ICON,
                  text: 'Autorize-me',
                  callback: this.trelloService.authorize
              }]; else {
                t.getRestApi()
                 .getToken()
                 .then(token => {
                    Trello.setToken(token);
                 }) 
                return [];
              }
            });
  }

  private getListActions = (t, opts) => {
    console.log("TrelloBootstrapService::getListActions():8");
    return t.list('name', 'id').then(list => {
      let menu = [];
      let correio=false, me=false;

      const ra = /.*(correio).*/gm;
      if (ra.exec(list.name.toLowerCase())!==null) {
        correio = true;
      } else {
        let ra = /.*(me).*/gm;
        if (ra.exec(list.name.toLowerCase()) !== null)
          me=true;
      }

      // Se for uma lista do correio inclui a exportação para o muambator
      if (correio) {
        menu.push({
          text: 'Exportar para o Muambator',
          callback: this.trelloService.exportaMuambator
        });
      }

      /*if (correio || me) {
        menu.push({
          text: 'Arquivar lista',
          callback: this.trelloService.exportaMuambator
        });
      }*/

      return menu;
  
    })
  }
}
