import { Injectable } from '@angular/core';
import { TrelloApiService } from './trello-api.service';

declare var TrelloPowerUp: any;  
declare var Trello: any;


function request<TResponse>(
  url: string, 
  config: RequestInit
): Promise<TResponse> {
  const baseUrl = 'https://api.nuvem2.tintanopano.com.br:8888/index.php';
  return fetch(baseUrl + url, config)
    .then(response => response.json())
    .then(data => data)
    .catch(error => {
      // Handle the error.
    })
}

/*const api = {
  get: <TResponse>(url: string) => 
  	request<TResponse>(url),
  
  // Using `extends` to set a type constraint:
  post: <TBody extends BodyInit, TResponse>(url: string, body: TBody) => 
  	request<TResponse>(url, { method: 'POST', body }),
}*/
"‌"
async function exportMuambator (t) {
  const list = await t.list('name','cards');

  console.log ('List name: '+list.name);
  let cards = list.cards;
  const reRastreio = /.*Rastreio: \[(.*)\]/gmu;
  const reCodigo = /(\d{4,5}) - /gm;
  const reMuambator = /.*\:white_check_mark\:Muambator/gm;
  const reCidadeEstado = /.*\(http:\/\/adm.*\) - (.*)-([A-Z][A-Z])$/gmu;
  const reName = /.*Cliente: \[(.*)\]/gm;
  const reBrand = /.* - .* - (.*)/gm;
  const rePlattform = /Pedido \[(.*)#.*\]/gm;
  const rePlattform2 = /Pedido \*\*(.*)#.*\*\*/gm;
  let nCardsTo = 0;
  let nProcessed = 0;
  for (let card of cards) {
    if (card.desc.match(reRastreio) && card.name.match(reCodigo) && !card.desc.match(reMuambator))
      nCardsTo++;
  }
  if (!nCardsTo) {
    t.alert ({
      message: 'Nenhum cartão a exportar para o Muambator'
    });
    return;
  }
  let nCard = 1;
  for (let card of cards) {
    let desc = card.desc;
    let name = card.name;
    if (desc.match(reRastreio) && name.match(reCodigo) && !desc.match(reMuambator)) {
      t.alert ({
        message: 'Processando cartão ' + nCard++ + '/' +nCardsTo
      });

      reRastreio.lastIndex=0;
      let tracking = reRastreio.exec(desc)[1];
      reCodigo.lastIndex=0;
      let code = reCodigo.exec(name)[1];
      reBrand.lastIndex=0;
      let m = reBrand.exec(name);
      let brand = (m) ? m[1] : null;
      console.log ('desc => '+ desc);
      console.log ('reName => '+reName);
      reName.lastIndex=0;
      m = reName.exec(desc);
      console.log(m);
      let client = m[1];
      rePlattform.lastIndex=0;
      m = rePlattform.exec(desc);
      if (!m) {
        rePlattform2.lastIndex=0;
        m = rePlattform2.exec(desc);
      }
      let plattform = m[1];
      reCidadeEstado.lastIndex=0;
      m = reCidadeEstado.exec(desc);
      let city = null, state=null;
      if (m) {
        let city = m[1];
        let state = m[2];
      }

      let descr = '';
      if (city && state) {
        descr = city + ' ' + state + ' ' 
      }
      descr += code + ' - ' + plattform + ' - ' + client;
      if (brand) {
        descr += ' - ' + brand;
      }


      let url = 'https://api.nuvem.tintanopano.com.br/index.php/muambator/tracking?XDEBUG_SESSION_START=netbeans-xdebug&tracking=' +
                  tracking + '&descr=' + descr;

      let data = await fetch(url, {method: 'POST', mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'tracking': tracking,
                              'client': code,
                              'plattform': plattform})
        })
        .then(response => response.json())
        .catch(error => {
          t.alert ({
            message: 'Falha no envio ' + error
          });
        });          
      
      console.log ('data => '+ data.body.processamento);
      nCard++;
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class TrelloService {

  constructor(private trelloPai: TrelloApiService) { }


  authorize(t, opts) {
    return t.popup({
      title: 'Autorização',
      items: [{
        text: 'Autorizo',
        callback: function (t, opts) {
          t.closePopup();
          let a = t.getRestApi();
          let return_url = window.location.href + 'auth-success';
          let b = a.authorize({ 'scope': 'read,write,account', 
                                'expiration': 'never',
                                'return_url': return_url,
                                'callback_method': function (token) {
                                  console.log('Tempo na Lista - Token: '+token);
                                }});
                                
          
          b.then(function(t) {
              alert('Autorizado!');
          }).catch(TrelloPowerUp.restApiError.AuthDeniedError, function() {
            alert('Cancelado!');
          });
        }
      }, {
        text: 'Não Autorizo',
        callback: function (t, opts) {t.closePopup();}
      }]
    });    
  }

  getListData(t = null) {
    //console.log("Tempo na Lista - TrelloService::getListData()");
    if (t) return t.list('all');
    return t.iframe().list('all');
  }


  getCardData(t = null) {
    //console.log("Tempo na Lista - TrelloService::getCardData()");
    if (t) return t.card('all');
    return t.iframe().card('all');
  }

  private static formatTimeDiff(date, now) {
    let timeDiff = Math.floor((now - date) / 1000);


    // Diferença em segundos
    let textValue: string;
    if (timeDiff < 60) {
      textValue = String(Math.floor(timeDiff)) + 's';
    } else if (timeDiff < 3600) {
      textValue = String(Math.floor(timeDiff / 60)) + 'm';
    } else if (timeDiff < 86400) {
      textValue = String(Math.floor(timeDiff / 3600)) + 'h';
    } else {
      textValue = String(Math.floor(timeDiff / 86400)) + 'd';
    }
    //console.log(`Tempo na Lista - TrelloService::formatTimeDiff() => ${textValue}`);
    return textValue;

  }


  exportaMuambator(t, opts) {
    exportMuambator(t);
  }

  ordenaCodigo(t, opts) {
    var sortedCards = opts.cards.sort(
      function(a,b) {
        let codA = null;
        let codB = null;

        //console.log ('ordena');

        const ra = /(\d{4,5}) - /gm;
        let m = ra.exec(a.name);
        if (m!==null) {
          codA = parseInt(m[1]);
        }

        const rb = /(\d{4,5}) - /gm;
        m = rb.exec(b.name);
        if (m!==null) {
          codB = parseInt(m[1]);
        }

        if ((codA) || (codB)) {
          if (!codA) {
            return -1;
          }

          if (!codB) {
            return 1;
          }

          if (codA > codB) {
            return 1;
          } else if (codB > codA) {
            return -1;
          }
          return 0;
        }

        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }
        return 0;
      });

    return {
      sortedIds: sortedCards.map(function (c) { return c.id; })
    };
  }

  getBadgeData(trello, opts) {
    let o = opts;
    let t = trello;
    //console.log("Tempo na Lista - TrelloService::getBadgeData()");
    return [{
        dynamic: function() {
          
    return t.card("all").then(card => {

      if (!card) {
          return;
      }

      const regex = /\d{4,5} - /gm;
      let m = regex.exec(card.name);
  
      if (m === null) {
          return {};
      }

      return t.get(card.id, 'shared', 'tpano_age_lastupdate', null)
        .then(lastRefresh => {
          if (lastRefresh === card.dateLastActivity)
            return t.get(card.id, 'shared', 'tpano_age_inlist', null)
                  .then (oldDate => {
                      //console.log (`Tempo na Lista - lendo da memória em ${card.id} (${card.shortLink})`);
                      return {
                        text: TrelloService.formatTimeDiff(oldDate,  Date.now()),
                        refresh: 12
                      };                   
                  }) 
          // console.log (`Tempo na Lista - lendo da API em ${card.id} opts(${o.context.card}) (${card.shortLink}) (${lastRefresh} !== ${card.dateLastActivity})`);
          return Trello.get('cards/'+card.id+'/actions', {filter: 'updateCard,createCard'}).then(actions => {
            if (!actions.length) {
              console.log ('Tempo na Lista - ERRO lendo actions')
              return {};
            }
            let now = Date.now();
            let oldDate = now;
            for (let action of actions) {
              if ((action.type === 'createCard') ||
                  ((action.data.hasOwnProperty('old')) &&
                  (action.data.old.hasOwnProperty('idList')))) {
                  oldDate = new Date(action.date).getTime();

                  return t.set(card.id, 'shared', {
                    tpano_age_inlist: oldDate,
                    tpano_age_lastupdate: card.dateLastActivity
                  }).then (ok => {
                    return {
                      text: TrelloService.formatTimeDiff(oldDate, now),
                      refresh: 12
                    };
                  },
                  error => {
                    console.log(`**ERRO: rejeitada gravação ${error}`);
                  })
                  .catch (erro => {
                    console.log (`***ERRO (${erro}) em Tempo na Lista - ao t.set('tpano_age_inlist', 'tpano_age_lastupdate') id ${card.shortLink}`)
                  });
              }
            }

            return {
              text: TrelloService.formatTimeDiff(oldDate, now),
              refresh: 60
            };
          });
      });
    })
  }}];
  }  
}
